<script>
  import { payPalStore } from "../../stores.js";
  import { payPalClientID } from "config";
  import { locale } from "svelte-i18n";
  var src = `https://www.paypal.com/sdk/js?client-id=${payPalClientID}&disable-funding=card&vault=true&currency=GBP`;

  // Once the paypal script has loaded, there will be a global paypal object we
  // can access.
  function onLoad() {
    payPalStore.set(window.paypal);
  }

  locale.subscribe((value) => {
    if (!value) {
      return;
    }

    let currency = "GBP";
    if (["fr", "it", "de", "nl"].includes(value)) {
      currency = "EUR";
    }

    src = `https://www.paypal.com/sdk/js?client-id=${payPalClientID}&disable-funding=card&vault=true&currency=${currency}`;
  });
</script>

<svelte:head>
  {#if src}
    <script {src} on:load={onLoad}></script>
  {/if}
</svelte:head>
