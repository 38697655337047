<script>
  import { onMount } from "svelte";
  import { googleAnalyticsID } from "config";
  import { page } from "@roxi/routify";
  import { checkOk, fetchJSON } from "../utils";
  import { loadingCount } from "../stores";

  import InitialLoad from "../components/InitialLoad.svelte";
  import PayPalScript from "../components/paypal/PayPalScript.svelte";
  import ServiceWorker from "../ServiceWorker.svelte";

  let countries, detectedCountryCode, symbols, rates;
  let host = "";
  if (process.env.config.environment == "development") {
    host = process.env.config.localServerUrl;
  }
  const loading = Promise.all([
    fetch(host + "/api/v1/countries")
      .then(checkOk)
      .then((response) => {
        detectedCountryCode =
          response.headers.get("X-Detected-Country-Code") || "GB";
        return response.json();
      })
      .then(({ data }) => {
        countries = data;
      }),
    fetchJSON("/api/v1/currency/symbols").then((data) => {
      symbols = data;
    }),
    fetchJSON("/api/v1/currency/rates").then((data) => {
      rates = data;
    }),
  ]);

  loadingCount.update((n) => n + 1);
  loading.finally(() => loadingCount.update((n) => n - 1));

  let ga;

  onMount(() => {
    // Update GTM each time the page changes
    ga = window.ga;
    if (ga && googleAnalyticsID) {
      ga("create", googleAnalyticsID, "auto");
    }
  });

  $: {
    const { path } = $page;
    if (ga) {
      ga("set", "page", path);
      ga("send", "pageview");
    }
  }
</script>

<svelte:head>
  <PayPalScript />
  <ServiceWorker />
</svelte:head>

<InitialLoad />

<slot scoped={{ loading, countries, symbols, rates, detectedCountryCode }} />

<style global>
  @import "normalize.css";
  @import "../styles/icomoon.css";
  @import "../styles/global.css";
</style>
