<script>
  import { loadingCount } from "../stores";

  import Loader from "./Loader.svelte";

  let loading;
  $: loading = $loadingCount > 0;
</script>

<Loader visible={loading} style="primary" />
